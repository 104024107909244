/* You can add global styles to this file, an also import other style files
//@import '~@angular/material/theming'
//$custom-typography: mat-typography-config($font-family: '"open sans", sans-serif')
//@include mat-core($custom-typography)
@import "assets/style-norelian.css"
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap')
body
  margin: 0
  background-color: #34495E
  color: #f5f5f5
  font-family: 'Open Sans' , sans-serif !important
button, input, optgroup, select, textarea 
  font-family: 'Open Sans' , sans-serif !important
.padding0
  padding: 0px !important
.font15
  font-size: 15px !important
.greenText
  color: #1AB394 !important
.border-bottom
  margin-bottom: 0px !important
.font12
  font-size: 12px !important
.font24
  font-size: 24px !important
  color: #2F4050 !important
  font-weight: 500 !important
.mat-snack-bar-container
  background: #0084c4 !important
  color: white !important

.mat-simple-snackbar
  span
    color: white !important
    font-size: 15px !important

.mat-simple-snackbar-action
  .mat-button
    box-shadow: none !important

.mat-simple-snackbar-action
  .mat-button
    .mat-button-wrapper
      font-size: 22px !important

.mat-snack-bar-container
  min-width: 100px !important
.button:focus
  outline: none
  background-color: none
.margin-bottom
  margin-bottom: 0px !important
.margin-top
  margin-top: 0px !important
.border-green
  border-bottom: solid 6px#1AB394
.opacity-text
  color: #686C6D !important
  opacity: 2 !important
  font-weight: 500 !important
.opacity-text-span
  color: #686C6D !important
  opacity: 2 !important
  font-size: 15px !important
.opacity-blod-span
  color: #2F4050 !important
  font-weight: bold !important
  font-size: 16px !important
.active-title
  margin-left: 0px !important
  left: 0px !important
.pointer
  cursor: pointer !important
.margin0
  margin: 0px !important
.btn, .btn-large, .btn-small, .btn-flat
  text-transform: none !important

.padding-left-rigth2
  padding-left: 2px !important
   padding-right: 2px !important
.justify
  text-align: justify
.padding30
  margin-left: 20px !important
.error
  background: #FF4855 !important
.custom_sneak_barss
  font-size: 24px !important
  width: 450px !important
  background: #1AB394 !important
  .mat-simple-snackbar
    .mat-simple-snackbar-action
      .mat-button
        font-color: #ffff
        .mat-button-wrapper
          font-size: 25px !important
          color: white !important

.text-greys
  color: #C3C3C3 !important
  opacity: 1 !important
  font-size: 18px !important
.text-greys-responsive
  color: #C3C3C3 !important
  opacity: 1 !important
  font-size: 1.1em !important
.up
  display: block !important
  z-index: 1003 !important
  width: 400px
  top: 25px !important
  transform: scaleX(1) scaleY(1)

.downs
  display: none !important
.text-terms
  color: #686C6D !important
.justify
  text-align: justify !important
.position-absolute
  position: absolute !important
.padding32
  padding: 32px !important
.padding24
  padding: 24px !important
.padding24-left-rigth
  padding: 24px 0px !important
.recaptcha-checkbox-border
  border: 1px solid #999999 !important
.btn.disabled
  color: #ffffff !important
  background: #C3C3C3  !important
.margin-left25
  margin-left: 35px !important
input
  color: #2F4050 !important
.line-height
  line-height : 1 !important
  font-size: 12px !important
.send
  background: #C3C3C3  !important
.send-color
  color: #C3C3C3  !important
.margin-top10
  margin-top: 10px   !important
.activateColor
  color: #1ab394 !important
select
  color: #2F4050 !important
.padding5
  padding-right: 0px!important
  padding-bottom: 5px !important
  padding-top: 5px !important
  padding-left: 45px !important
.arrow
  color: #2F4050 !important
.errorPassword
  background: #FF4855
  color: white
  padding: 10px
  font-size: 0.7em !important
  border-radius: 3px

.modal-overlay
  background: #0000007a !important
.upt
  display: block !important
  z-index: 500 !important
  width: 100% !important
  transform: scaleX(1) scaleY(1)
.up
  display: block !important
  z-index: 1003 !important
  width: 400px
  top: 35px !important
  transform: scaleX(1) scaleY(1)

.ups
  z-index: 1002 !important
  display: block !important
  opacity: 0.5 !important

.downs
  display: none !important

.modal.modal-fixed-footer
  padding: 0
  height: 70%

.modal.modal-fixed-footer .modal-content
  position: absolute
  height: calc(100% - 56px)
  max-height: 100%
  width: 100%
  overflow-y: auto

.modal.modal-fixed-footer .modal-footer
  border-top: 1px solid rgba(0, 0, 0, .1)
  position: absolute
  bottom: 0

.modal.bottom-sheet
  top: auto
  bottom: -100%
  margin: 0
  width: 100%
  max-height: 45%
  border-radius: 0
  will-change: bottom, opacity
.left-shadow
  box-shadow: -2px 0 1px -1px blue
.padding-right0
  padding-right: 0px !important
.rigth-shandow
  box-shadow: 5px 0 5px -5px blue
.padding32-8
  padding: 32px 16px !important  
.padding32-rigth-top-bottom
  padding-right: 8px   !important  
  padding-top: 32px !important  
  padding-bottom: 32px !important  
  padding-left : 0px !important
.padding16
  padding: 16px !important
.language
  border: none !important
  background: transparent !important
  color: #DDDDDD !important
  font-size: 16px !important
  border-bottom: 0px !important

.boder-left-rigth-top
  background: #FFFFFF 0% 0% no-repeat padding-box
  box-shadow: 0px 1px 4px #00000038
  border-radius: 4px
  opacity: 1
.border-error
  border-bottom: 1px solid #FF4855 !important  
.border-error-two
  border-bottom:  #FF4855 !important    
.padding-top-bottom11
  padding-top: 11px !important
  padding-bottom: 11px !important
.padding-right0
  padding-right : 0px !important  
select
  .language
    border: none !important
    background: transparent !important
    color: #DDDDDD !important
    font-size: 16px !important
    border-bottom: 0px !important
.language:focus
  outline: none !important
  border-bottom: 0px !important
.activatetext
  color : #1AB394  
    
html,h1,h2,h3,h4,h5,h6,span,a,p,input,label
  font-family: 'Open Sans' , sans-serif !important